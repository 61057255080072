@use '@chicksgroup/styles/variables' as v;

footer:has(#footer) {
    display: flex;
}

#footer {

    .legal-page-skeleton {
        border-top: unset !important;
    }

    &.skeleton-policy::after {
        background-color: unset !important;
    }

    .legal-page-skeleton {
        border-top: unset !important;
    }

    .skeleton-loading {
        display: flex;

        &.title-1 {
            width: 153px !important;
            height: 56px;
        }

        &.title-2 {
            width: 60px;
            height: 24px;
        }

        &.title-3 {
            width: 128px;
            height: 41px;
        }

        &.title-4 {
            width: 320px !important;
            height: 21px;
        }

        &.link {
            height: 17px;
            width: 200px;
        }

        &.support {
            width: 150px;
            height: 16px;
        }

        &.idiom {
            width: 118px;
            height: 20px;
        }
    }

    --cds-social-circles-network-height: 32px;
    --cds-social-circles-network-width: 32px;

    position: relative;
    bottom: 0;
    padding: v.$largePad v.$bigPad v.$regularPad;
    margin-top: auto;
    border-top: 1px solid var(--cg-grey-400);
    background-color: var(--cg-grey-100);
    height: 384px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    letter-spacing: normal;

    @media (width < v.$desktop) {
        padding: v.$bigPad 20px v.$regularPad;
        height: 296px;
    }

    @media (width < v.$tablet) {
        padding: v.$regularPad;
        height: auto;

        &.trade-footer {
            margin-bottom: 65px;
        }
    }

    &.trade-footer {
        margin-bottom: 40px;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        max-width: 1250px;
        height: 176px;
        gap: 132px;
        width: 100%;

        @media (width < v.$widescreen) {
            gap: 50px;
        }

        @media (width < v.$desktop) {
            gap: 0;
            height: auto;
        }

        @media (width < v.$tablet) {
            flex-direction: column;
            justify-content: start;
            align-items: center;
        }

        .info-section {
            font-size: v.$regular;
            padding-top: 20px;
            color: var(--cg-grey-1100);

            @media (width >=v.$desktop) {
                width: 276px;
            }

            @media (width < v.$desktop) {
                padding-top: 0;
                width: 204px;
            }

            @media (width < v.$tablet) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 32px;
                width: 224px;
            }

            .logo {
                width: 196px;
                height: 56px;
                margin-bottom: 8px;

                img {
                    width: 153px;
                    height: 100%;

                    @media (width < v.$tablet) {
                        width: 100%;
                    }
                }
            }

            .exchange-link {
                color: var(--cg-grey-700);
                line-height: v.$medium;
                font-weight: 300;

                &:hover {
                    text-decoration: underline !important;
                }

                @media (width < v.$tablet) {
                    display: block;
                    text-align: center;
                }

                @media (width >=v.$tablet) {
                    text-wrap: nowrap;
                }

            }

            .contact-email {
                margin-top: 12px;
                color: var(--cg-grey-700);
                line-height: v.$medium;
                font-weight: 300;

                &:hover {
                    text-decoration: underline !important;
                }

                @media (width < v.$desktop) {
                    margin-top: 12px;
                }

                @media (width < v.$tablet) {
                    margin-top: 20px;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .main-content {
            display: flex;

            @media (width < v.$desktop) {
                margin-left: 80px;
            }

            @media (width < v.$tablet) {
                margin: 0;
                width: 100%;
            }

            .links-section-tablet-desktop {
                display: flex;
                gap: 132px;
                padding-top: 20px;
                position: relative;

                @media (width < v.$tablet) {
                    display: none;
                }

                @media (width < v.$extrascreen) {
                    gap: 100px;
                }

                @media (width < v.$widescreen) {
                    gap: 60px;
                }

                @media (width < v.$desktop) {
                    gap: 48px;
                    margin-right: 20px;
                }
            }

            .links-section-mobile {
                display: none;
                width: 100%;

                @media (width < v.$tablet) {
                    display: flex;
                    flex-direction: column;
                }
            }

            .trustpilot-reviews-tablet {
                position: absolute;
                top: calc(100% + 8px);
                left: calc(100% - 104px);

                @media (width >=v.$desktop) {
                    display: none;
                }

                a,
                span {
                    color: var(--cg-grey-900);
                }

                a {
                    font-weight: 400;
                    white-space: nowrap;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                span {
                    font-weight: 700;
                    margin-left: 2px;
                }

                .stars {
                    margin-bottom: 0 !important;
                }
            }
        }

        .right-section {

            @media (width < v.$widescreen) {
                padding-left: 0px;
            }

            @media (width < v.$tablet) {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }

            .social-media {
                padding-top: 20px;
                letter-spacing: 0.448px;
                color: var(--cg-grey-900);
                font-size: v.$largeplus;
                font-weight: 500;
                line-height: normal;

                span {
                    margin-bottom: 20px;
                }

                @media (v.$tablet <=width < v.$desktop) {
                    position: absolute;
                    left: 20px;
                    top: 156px;
                }

                @media (width < v.$tablet) {
                    padding-top: 0;
                    margin-bottom: 32px;
                }

                .social-media-circles {
                    width: 152px;
                    --md-icon-size: 20px;
                    --cds-icon-color: #FCFCFC;
                }

            }

            .trustpilot-reviews {
                margin-top: 16px;
                width: 128px;
                line-height: v.$medium;

                a,
                span {
                    color: var(--cg-grey-900);
                }

                a {
                    margin-bottom: 8px;
                    font-weight: 400;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                .trust-pilot-text {
                    font-weight: 700;
                    line-height: 16px;
                }

                @media (v.$tablet <=width < v.$desktop) {
                    display: none;
                }

                @media (width < v.$tablet) {
                    margin-top: 32px;
                    text-align: center;
                }
            }
        }

    }

    .bottom-section {
        color: var(--cg-grey-700);
        font-weight: 400;
        letter-spacing: 0.035px;

        @media (width < v.$phone) {
            font-size: v.$small;
        }
    }
}