@import 'styles/variables';
@import 'styles/mixins';

#get-started {
    .container {
        @include container-fluid();
        max-height: unset;
        display: grid;
        gap: 40px;

        @media (width >= $tablet) {
            max-width: calc(100% - 40px);
            max-height: 670px;
        }

        @media (width >= $desktop) {
            max-width: calc(100% - 40px);
            margin: 0 auto;
        }

        @media (width >= $extrawidescreen) {
            max-width: 1560px;
        }
    }

    .skeleton-size {
        width: 100%;

        &.s1 {
            height: 50px !important;
        }

        &.s2 {
            height: 50px !important;
        }

        &.s3 {
            height: 170px !important;
        }
    }

    .form-section:has(.skeleton-loading) {
        outline: none !important;
    }

    .exchange-section, .form-section {
        border-radius: 12px;
        padding: 0 $regularPad;

        .line-1.skeleton-size {
            height: 76px;
            margin-bottom: 10px;

            @media (width >= $tablet) {
                height: 38px;
            }

            @media (width >= $desktop) {
                height: 80px;
                margin-bottom: 15px;
            }
        }
        .line-2.skeleton-size {
            height: 38px;

            @media (width >= $tablet) {
                height: 25px;
            }

            @media (width >= $desktop) {
                height: 64px;
            }
        }

        .banner.skeleton-size {
            height: 130px;
            margin-top: 20px;
        }

        @media (width >= $tablet) {
            padding: 20px;
            outline: 1px solid var(--cg-grey-400);
        }

        @media (width >= $desktop) {
            padding: 40px 40px 20px 40px;
            max-height: 396px;
            outline: 1px solid var(--cg-grey-400);
        }
    }

    .header-title {
        p {
            margin: 0;
            margin-bottom: 10px;

            @media (width >= $desktop) {
                margin-bottom: 15px;
            }
        }

        p, span, h1 {
            font-size: $largeplus !important;
            margin-bottom: 10px;

            @media (width >= $phone) {
                font-size: $extralarge !important;
            }

            @media (width >= $desktop) {
                font-size: $moreplushuge !important;
                line-height: $bigPad;
                margin-bottom: 15px;
            }
        }
    }

    .header-item {
        font-size: $regular;
        line-height: 19px;
        color: $gray-blue;

        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0;

            @media (width >= $desktop) {
                margin-bottom: $regularPad;
            }
        }

        @media (width >= $phone) {
            font-size: $medium;
            line-height: 25px;
        }

        @media (width >= $desktop) {
            font-size: $extralarge;
            line-height: 32px;
        }
    }

    .check-icon {
        @include square(24px);
        margin-right: 10px;
        margin-top: -2.5px;
    }
}
